import Vue                            from 'vue'
import App                            from './App.vue'
import VueSweetalert2                 from 'vue-sweetalert2';
import Snotify, { SnotifyPosition }   from 'vue-snotify';
import 'vue-snotify/styles/material.css';

//-- Bootstrap
//require('bootstrap');
//require('bootstrap/dist/css/bootstrap.min.css');

//-------------------------------------------
//-- Bootstrap para Vue
//-------------------------------------------
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


//-------------------------------------------
//-- VueSweetAlert2 = SWAL
//-------------------------------------------
const optionsSwal = {
  confirmButtonColor: '#595cde',
  cancelButtonColor: '#fd8b0d',
};

Vue.use(VueSweetalert2, optionsSwal);

//-- Vue-Snotify = Snotify
const optionsSnotify = {
  toast: {
    position: SnotifyPosition.rightTop
  }
}

Vue.use(Snotify, optionsSnotify);

//-------------------------------------------
//-- Multi-Select 
//-------------------------------------------
import VueSelectSides from "vue-select-sides";
Vue.use(VueSelectSides, {locale: "es_ES"});
Vue.component("vue-select-sides", VueSelectSides);
import "vue-select-sides/styles/themes/soft.scss";


//-------------------------------------------
//-- Ventana Modal (https://euvl.github.io/vue-js-modal)
//-------------------------------------------
import VModal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'
Vue.use(VModal, { componentName: 'VModal' });


//-------------------------------------------------
//-- Paleta de Colores
//-------------------------------------------------
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
Vue.component("v-swatches", VSwatches);

//-------------------------------------------------
//-- Indicador de Progreso
//-------------------------------------------------
import VueEllipseProgress from 'vue-ellipse-progress';
Vue.use(VueEllipseProgress);

//-------------------------------------------
//-- Importar Otros Módulos Adicionales
//-------------------------------------------
import igs_routes                       from '@/igs-routes/igs_routes'
import igs_store                        from '@/igs-store/igs_store'


//-------------------------------------------
//-- Importacion de JQuery
//-------------------------------------------
window.$ = window.jQuery = require('jquery');

//-------------------------------------------
//-- Vue Draggable
//-------------------------------------------
import draggable from "vuedraggable";
Vue.component("draggable", draggable);

//-------------------------------------------
//-- Short Key Support
//-------------------------------------------
Vue.use(require('vue-shortkey'));

//-------------------------------------------
//-- QR Display Component
//-------------------------------------------
import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

//----------------------
//-- FontAwesome
//----------------------

//-- Core
import { library } from '@fortawesome/fontawesome-svg-core'
//-- Icons
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
//-- Vue
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//-- Añadir Iconos a la Biblioteca
library.add(faUserSecret)
//-- Importar Iconos
import '@fortawesome/fontawesome-free/css/all.css'
//-- Usar el componente
Vue.component('font-awesome-icon', FontAwesomeIcon)


//-------------------------------------------------------------
//-- Determinar si estamos en modo Desarrollo / Producción
//-------------------------------------------------------------
Vue.config.productionTip = false

//------------------------------------------------------------
//-- Variables de Acceso Global (y, generalmente, estático)
//------------------------------------------------------------
global.lnkAppName                   = "Panel iAlmacén";                   //-- Nombre de la App
global.lnkAppVersion                = "0.1.3-240809"                      //-- Versión de la App

global.lnkServerAppURL              = 'https://app.ialmacen.es/app?';      //-- URL de acceso a los Datos del Servidor Remoto (Contenido)
global.lnkServerImgURL              = 'https://panel.ialmacen.es/img/';    //-- URL para Descarga de Imágenes
global.lnkServerImgArticulosURL     = 'https://app.ihoreca.es/img?';       //-- URL para Descarga de Imágenes

global.lnkGetDataOk                 = 'ia1-OK';                           //-- Tag OK
global.lnkGetDataError              = 'ia1-ERROR';                        //-- Tag ERROR

global.lnkDateSeparator             = "-";                              //-- Separador para Fechas ("-", "/", ...)
global.lnkTimeSeparator             = ":";                              //-- Separador para Horas  (":", ...)

global.lnkMinDate                   = "00000101";                       //-- Fecha Mínima
global.lnkMaxDate                   = "99991231";                       //-- Fecha Máxima

global.lnkDebugLevel                = 1;                                //-- 0-Omitir mensajes en Consola
                                                                        //-- 1-Error // 2-Info // 3-Debug
                                                                        //-- 4-Temp // 5-Trace


//-- CSS (Paleta Colores)  (tc=Text Color // bc = Background Color)
global.lnkDefaultCSS                = '.ns-root { --tc0 : #ffffff; --tc1 : #595cde; --tc2 : #fd8b0d; --tc3 : #fd8b0d; --tc4 : #595cde; --tc5 : #fd8b0d; --tc6 : #ffffff; --tc7 : #7f7f7f; --tc8 : #dedede; --tc9 : #ffffff; --bc0 : #595cde; --bc1 : #595cde; --bc2 : #fd8b0d; --bc3 : #0369cf; --bc4 : #595cde; --bc5 : #ffffff; --bc6 : #fd8b0d; --bc7 : #7f7f7f; --bc8 : #dedede; --bc9 : #ffffff; }';
global.lnkDefaultLIC                = ["#595cde", "#595cde", "#fd8b0d", "#dedede", "#7f7f7f"];

//-- Paleta de Colores para los Huecos del Mapa
                                    //-- *------------------------------ Stock ----------------------------------*  *--------- Búsquedas ---------*   *------------------------------------------------------------------------------------------------ Hueco ---------------------------------------------------------------------------------------------------------------------------*
                                    //--                                                                                                                                            C/Mvtos.COMPRA   C/Mvtos.COMPRA   C/Mvtos.CARGA   C/Mvtos.CARGA    C/Mvtos.RETORNO  C/Mvtos.RETORNO   C/Mvtos./ALMAC.   C/Mvtos/ALMAC.    C/Mvtos.RECUENTO   C/Mvtos.RECUENTO    c/Mvtos.VARIOS     c/Mvtos.VARIOS                                                      
                                    //-- Negativo    Cero       < 33%     >=33 <66  >=66 <100%   100%      > 100%      No       Si & No       Sí      Últ.Activo  Agrup.Desconocida NO CONFIRMADOS    CONFIRMADOS     NO CONFIRMADOS    CONFIRMADOS    NO CONFIRMADOS     CONFIRMADOS     NO CONFIRMADOS     CONFIRMADOS       NO CONFIRMADOS    CONFIRMADOS         NO CONFIRMADOS      CONFIRMADOS       
                                    //--    (0)       (1)         (2)       (3)        (4)        (5)        (6)       (7)         (8)        (9)        (10)         (11)              (12)              (13)            (14)             (15)             (16)              (17)             (18)             (19)               (20)               (21)                (22)               (23)
global.lnkDefaultMPAColors          = [ "#808080", "#ffffff", "#ff0000", "#ffff00", "#009900", "#006600", "#006666", "#ffaaaa", "#ffffdd", "#aaffaa", "#ff007f",   "#000000",        "#99ccff",        "#0080ff",       "#ffcc99",       "#ff8000",       "#ccffff",       "#00ffff",       "#cc99ff",        "#7f00ff",        "#ff99cc",       "#ff00ff",           "#c0c0c0",         "#808080"    ];

//..................................
//-- Gradientes Ocupación Hueco
//..................................

//-- Gradiente para Stock NEGATIVO (POH < 0%)
global.lnkDefaultGSN = '#606060';

//-- Gradiente para Stock CERO (POH == 0%)
global.lnkDefaultGSZ = '#FFFFFF';

//-- Gradiente para Stock "Dentro de Rangos" (POH >= 0,01% y <= 100%)
                          //-- Rojo                    
global.lnkDefaultGOH = ['#FF0000', '#FF1100', '#FF2200', '#FF3300', '#FF4400', '#FF5500', '#FF6600',
                        '#FF7700', '#FF8800', '#FF9900', '#FFAA00', '#FFBB00', '#FFCC00', '#FFDD00',
                        '#FFEE00',
                        '#FFFF00',                  //-- Amarillo
                        '#EEFF00', 
                        '#DDFF00', '#CCFF00', '#BBFF00', '#AAFF00', '#99FF00', '#88FF00', '#77FF00', 
                        '#66FF00', '#55FF00', '#44FF00', '#33FF00', '#22FF00', '#11FF00', '#00FF00'];
                                                                                          //-- Verde
//-- Gradiente para Stock HIPER-POSITIVO (POH > 100%)
global.lnkDefaultGSP = '#CC00CC';                                                                                          

//-----------------------------------------------------------------------------------------------
//-- Configurar RUTAS para ser usadas a lo largo de toda la App
//-----------------------------------------------------------------------------------------------
Vue.prototype.$lnkListaPaginas = igs_routes

//-----------------------------------------------------------------------------------------------
//-- Configurar STORE para ser usado a lo largo de toda la App
//-----------------------------------------------------------------------------------------------
Vue.prototype.$store = igs_store

/* eslint-disable */
const app = new Vue({
  render: h => h(App),
  router:igs_routes,
  store:igs_store,
  data: { },
  created() {
    window.addEventListener('resize', this.gp99HandleResize);
    this.gp99HandleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.gp99HandleResize);
  },
  mounted() {

    //console.log('### App Mounted ###  (Inicio)');

      //--------------------------------
      //-- Inicializaciones
      //--------------------------------
      console.log('### iAlmacén Mounted ###  (Fin)');

    /*
    //-- Ejemplo de cómo llamar a Métodos VUE desde JQuery
    //-- Se puede llamar con app.XXXX a métodos locales definidos en la
    //-- instancia de VUE, y esos métodos locales Javascript (definidos en methods
    //-- durante la instanciación de VUE) ya ¡¡ pueden llamar a métodos VUE !!
    $(function() {
      $(document).on('click', '.alert-close', function() {
          //-- Ocultar Anuncio actual
          $(this).parent().hide();

          //-- Inicializar Texto
          if ($(this).parent()[0].id == 'igs-foot-alert') {
            app.gm10StrFootMsg();   //-- Pie (Footer)
          } else {
            app.gm10StrHeadMsg();   //-- Cabecera (Heading)
          }
      })
    });
    */
  },
  methods: {

    //-- Actualizar Ancho y Alto de la Ventana
    gp99HandleResize() {
      //console.log('-- gp99HandleResize --');
      //console.log(window.innerWidth + ' / '+window.innerHeight)
      this.$store.commit('p10StrScreenWidth', window.innerWidth);
      this.$store.commit('p10StrScreenHeight', window.innerHeight);
    }

    /*
    //-- Ejemplo de métodos locales JavaScript que realizan llamada
    //-- a métodos VUE. Con frecuencia estos métodos serán utilizados
    //-- por métodos y funciones JQuery

    //-- Global Method : Inicialización Texto del Mensaje de la parte superior (Heading)
    gm10StrHeadMsg: function() {
      //console.log('[gm10StrHeadMsg] ('+texto+')');
      //this.$store.commit('p10StrHeadMsgTexto', '');
    },
    //-- Global Method : Inicialización Texto del Mensaje de la parte inferior (Footer)
    gm10StrFootMsg: function() {
      //console.log('[gm10StrFootMsg] ('+texto+')');
      //this.$store.commit('p10StrFootMsgTexto', '');
  }
  */

}
}).$mount('#app')
/* eslint-enable */

//-- Iniciar la App en la Página Principal
igs_routes.replace('/')

//-- Comprobación de Arranque correcto de JQuery
// eslint-disable-next-line no-undef
$(document).ready(function () {
  //console.log('JQuery ¡¡ ON !!');

  //-- Ejemplo de llamada a método local Javascript (que, a su vez, realiza llamada a métodos VUE)
  //app.gm10StrHeadMsg('1234'); 
})


